import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';

import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';
import '../globalStyles.css';
import Layout from '../components/Layout';
import CustomerReferences from '../components/CustomerReferences';

const SubpageWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const SubpageContainer = styled.div`
  margin: auto;
`;

const MainSection = styled.div`

`;
const TealBackground = styled.div`
  background-color: var(--color-teal);
`;
const SubpageHeader = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  padding: 50px 100px 100px 100px;

  @media (max-width: 1350px) {
    padding: 50px;
  }

  @media (max-width: 1023px)  {
    flex-direction: column;
    padding: 50px 25px;
  }

  @media (max-width: 550px) {
    padding: 25px 0 50px 0;
  }
`;

const TextSectionHeader = styled.div`
  width: 50%;
  color: var(--color-white);

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    margin: auto;
  }
`;

const Headline = styled.h1`
  font-size: 54px;
  white-space: pre-wrap;
  margin-bottom: 6%;
  margin-top: 8%;

  & p {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 1600px) {
    font-size: 44px;
  }

  @media (max-width: 1250px) {
    font-size: 36px;
  }

  @media (max-width: 1023px) {
    margin: auto;
    margin-top: 0;
    width: 80%;
    padding-bottom: 3%;
  }

  @media (max-width: 900px) {
    font-size: 38px;
    width: 90%;
  }

  @media (max-width: 750px) {
    font-size: 37px;
    padding-bottom: 2%;
    width: 95%;
  }

  @media (max-width: 700px) {
    font-size: 28px;
    padding-bottom: 4%;
  }

  @media (max-width: 550px) {
    width: 95%;
    margin: auto;
    padding-bottom: 5%;
  }

  @media (max-width: 450px) {
    font-size: 23px;
  }
`;

const Subtext = styled.div`
  white-space: pre-wrap;
  font-size: 20px;
  margin-top: 0;
  width: 90%;
  margin-bottom: 8%;
  line-height: 1.25;

  & p {
    margin-top: 0;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1600px) {
    font-size: 18px;
    width: 100%;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    width: 85%;
    margin: auto;
    margin-bottom: 5%;
  }

  @media (max-width: 900px) {
    width: 90%;
    font-size: 17px;
  }

  @media (max-width: 750px) {
    width: 95%;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    margin: auto;
    white-space: normal;
    margin-bottom: 8%;
  }

`;

const HeaderButton = styled.button`
  color: var(--color-white);
  background-color: var(--color-magenta);
  font-size: 24px;
  padding: 15px 30px;
  transition: none;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

  &:hover {
    background-size: 0;
  }

  @media (max-width: 1600px) {
    font-size: 19px;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
    padding: 15px;
  }

  @media (max-width: 1050px) {
    font-weight: 700;
  }

  @media (max-width: 1023px) {
    font-weight: 900;
    font-size: 20px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    font-size: 17px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    margin: 0 2.5%;
  }
`;

const InfoTextHeaderButton = styled.p`
  margin: 5px;
  font-size: 14px;

  @media (max-width: 1600px) {
    font-size: 12px;
  }

  @media (max-width: 700px) {
    font-size: 11px;
  }

  @media (max-width: 450px) {
    margin: 5px 15px;
  }
`;

const ImageWrapperHeader = styled.div`
  width: 36%;
  padding-top: 40.57%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    width: 60%;
    padding-top: 60.86%;
    margin-top: 5%;
    margin-bottom: 10%;
  }

  @media (max-width: 900px) {
    width: 75%;
    padding-top: 76.07%;
  }

  @media (max-width: 700px) {
    width: 90%;
    padding-top: 91.28%;
  }
`;

const BackgroundBubble = styled.div`
  background-image: ${bubbleSvg(lightTeal, 45)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 125%;
  height: 125%;
  left: 10%;
  right: 0;
  top: -10%;
  bottom: 0;

  @media (max-width: 1250px) {
    width: 130%;
  }

  @media (max-width: 1023px) {
    left: 0;
    top: 0;
    width: 120%;
    height: 120%;
  }

  @media (max-width: 700px) {
    left: -10%;
  }

  @media (max-width: 550px) {
    width: 130%;
    height: 130%;
  }
`;

const ScreenContainerHeader = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  height: 100%;
  width: 90.5%;
  max-width: 704.33px;
  position: absolute;
  left: 12.2%;
  right: 0;
  top: -15.8%;
  bottom: 0;

  @media (max-width: 1023px) {
    left: 5.2%;
    top: -8%;
  }
`;

const ImageContainerHeader = styled.div`
  background-image: url(/img/mockup_tablet.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 7%;
  right: 0;
  top: -16%;
  bottom: 0;

  @media (min-width: 1921px) {
    max-width: 774px;
  }

  @media (max-width: 1023px) {
    left: 0;
    top: -8.2%;
  }
`;

const StatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 50px;
  position: relative;
  margin-top: -39%;
  width: 65%;
  height: 180px;
  margin-left: 22%;

  @media (max-width: 1600px) {
    border-radius: 30px;
    height: 145px;
    width: 70%;
    margin-left: 16%;
  }

  @media (max-width: 1023px) {
    margin-top: -22%;
    height: 130px;
    width: 60%;
    margin-left: 54%;
  }

  @media (max-width: 900px) {
    width: 55%;
  }

  @media (max-width: 700px) {
    margin-left: 42%;
  }

  @media (max-width: 550px) {
    width: 75%;
    margin-left: 25%;
    margin-top: -24%;
  }
`;

const StarSection = styled.div`
  display: flex;
  height: 20%;
  width: 35%;
  justify-content: space-between;
  margin: auto;
  padding-top: 5%;

  @media (max-width: 1600px) {
    padding-top: 3%;
  }

  @media (max-width: 1250px) {
    width: 40%;
  }

  @media (max-width: 1023px) {
    width: 34%;
    margin-bottom: 0;
  }

  @media (max-width: 900px) {
    width: 32%;
  }

  @media (max-width: 550px) {
    width: 29%;
  }

  @media (max-width: 400px) {
    width: 35%;
  }

`;

const StarImage = styled.div`
  background-image: url(/img/star_magenta.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 28px;
  height: 28px;

  @media (max-width: 1600px) {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1023px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 900px) {
    width: 20px;
    height: 20px;
  }
`;

const StarImage2 = styled(StarImage)`

`;

const StarImage3 = styled(StarImage)`

`;

const Statement = styled.div`
  width: 80%;
  margin: auto;
  font-size: 16px;
  color: var(--color-dark-grey);
  text-align: center;
  white-space: pre-wrap;

  & p {
    margin-top: 0;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }

  @media (max-width: 1400px) {
    font-size: 13px;
  }

  @media (max-width: 1250px) {
    font-size: 12px;
  }

  @media (max-width: 550px) {
    font-size: 11px;
    width: 88%;
  }

`;

const CustomerReferencesContainerHeader = styled.div`
  max-width: 1920px;
  margin: auto;
  background-color: var(--color-teal);
  padding: 50px 80px 30px 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1350px) {
    padding: 60px 40px 20px 40px;
  }

  @media (max-width: 1023px) {
    padding: 60px 25px 20px 25px;
  }

  @media (max-width: 900px) {
    padding: 110px 5px 15px 5px;
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 450px) {
    justify-content: space-around;
  }
`;

const CustomerImage = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 80%;

    @media (max-width: 1250px) {
      width: 110%;
    }

    @media (max-width: 1023px) {
      width: 135%;
    }

    @media (max-width: 900px) {
      width: 160%;
    }

    @media (max-width: 350px) {
      width: 200%;
    }
  }

  @media (max-width: 700px) {
    width: 15%;
    height: 50px;
  }

  @media (max-width: 550px) {
    width: 22%;
  }

  @media (max-width: 450px) {
    width: 26%;
  }

  @media (max-width: 350px) {
    width: 22%;
  }
`;

const VideoContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const VideoHeadline = styled.h2`
  margin: auto;
  text-align: center;
  font-weight: 900;
  font-size: 32px;
  padding-top: 5%;
  max-width: 95%;

  & p {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 1250px) {
    font-size: 28px;
  }

  @media (max-width: 1023px) {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
    padding-top: 8%;
  }
`;

const VideoSection = styled.div`
  display: flex;
  justify-content: center;
  height: 31.7vw;
  padding-top: 3%;

  @media (min-width: 1921px) {
    width: 1122px;
    height: 631.125px;
    margin: auto;
  } 
  
  @media (max-width: 900px) {
    height: 50.6vw;
  }

  @media (max-width: 550px) {
    height: 53.5vw;
  }

  & iframe {
    width: 56.25%;
    height: 100%;
    border: none;

    @media (min-width: 1921px) {
      width: 1122px;
      height: 631.125px;
    }

    @media (max-width: 900px) {
      width: 90%;
    }
    
    @media (max-width: 550px) {
      width: 95%;
    }
  }
`;

const ShareLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 7%;
  padding-top: 3%;

  @media (max-width: 900px) {
    padding-top: 5%;
  }
`;

const ShareLink = styled.div`
  margin: auto;
  background-color: var(--color-magenta);
  font-size: 24px;
  font-weight: 900;
  padding: 15px 100px;

  & a {
    text-decoration: none;
    color: var(--color-white);
  }

  @media (max-width: 1600px) {
    font-size: 19px;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1050px) {
    font-weight: 700;
  }

  @media (max-width: 1023px) {
    font-weight: 900;
    font-size: 20px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    font-size: 17px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    padding: 15px 75px;
   }

`;

const ShareLinkText = styled(InfoTextHeaderButton)`

`;

const TrustFactContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  font-size: 18px;

  @media (max-width: 1250px) {
    font-size: 17px;
    max-width: 95%;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
    padding-bottom: 5%;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

const TrustFactSection = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding-bottom: 5%;

  @media (max-width: 550px) {
    width: 100%;
    padding-bottom: 8%;
  }
`;

const TrustFactIcon = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60px;

  @media (max-width: 900px) {
    height: 50px;
  }

  @media (max-width: 400px) {
    height: 40px;
  }
`;

const TrustFactHeadline = styled.p`
  font-weight: 700;
`;

const TrustFactText = styled.p`
  max-width: 60%;
  margin: auto;
  margin-top: 0;

  @media (max-width: 900px) {
    max-width: 80%;
  }

  @media (max-width: 400px) {
    max-width: 95%;
  }
`;

const CustomerReferenceFromHomepage = styled.div`
  background-color: var(--color-teal);
`;

const FAQContainer = styled.div`
  padding: 100px;
  max-width: 1920px;
  margin: auto;

  @media (max-width: 1350px) {
    padding: 100px 50px;
  }

  @media (max-width: 1023px) {
    padding: 100px 25px;
  }

  @media (max-width: 550px) {
    padding: 50px 2.5% 75px 2.5%;
  }
`;

const FAQHeadline = styled.h2`
  margin: auto;
  font-weight: 900;
  font-size: 32px;
  color: var(--color-dark-grey);
  margin-bottom: 3%;
  margin-top: 0;

  @media (max-width: 1250px) {
    font-size: 24px;
  }

  @media (max-width: 1023px) {
    font-size: 22px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
  }
`;

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-dark-grey);
  justify-content: space-between;
  border-bottom: 1px solid;

  & label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    cursor: pointer;
    transition: color 350ms ease;

    & h2 {
      font-weight: 400;
      font-size: 24px;

      @media (max-width: 1250px) {
        font-size: 20px;
      }
    
      @media (max-width: 1023px) {
        font-size: 18px;
      }
    
      @media (max-width: 900px) {
        font-size: 16px;
      }
    
      @media (max-width: 700px) {
        font-size: 14px;
      }
    }

    & svg {
      width: 30px;
      margin: 5px 5px 5px 20px;
      flex-shrink: 0;
      transition: transform 350ms ease;

      @media (max-width: 900px) {
        width: 20px;
      }
    }
  }

  &.tab input:checked ~ .tab__label svg {
    transform: rotate(180deg);
  }

  &.tab input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  & .tab__content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.35s;
    padding-left: 3%;
    font-size: 22px;
    width: 80%;
    line-height: 1.25;

    & p {
      @media (max-width: 700px) {
        margin-top: 0;
      }  
    }

    @media (max-width: 1250px) {
      font-size: 20px;
    }
  
    @media (max-width: 1023px) {
      font-size: 18px;
    }
  
    @media (max-width: 900px) {
      font-size: 16px;
    }
  
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  &.tab input:checked ~ .tab__content {
    max-height: 100vh;
    color: var(--color-blue);
  }
`;

const PreparationContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0 100px 100px 100px;
  color: var(--color-dark-grey);
  display: flex;

  @media (min-width: 1921px) {
    padding-top: 0;
  }

  @media (max-width: 1350px) {
    padding: 0 50px 100px 50px;
  }

  @media (max-width: 1023px) {
    padding: 0 25px 100px 25px;
    flex-direction: column;
  }

  @media (max-width: 550px) {
    padding: 0 0 50px 0;
  }
`;

const PreparationHeadline = styled(FAQHeadline)`
  margin-left: 0;

  @media (max-width: 550px) {
    width: 95%;  
  }
`;

const PreparationTextBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 53%;
  font-size: 18px;
  flex-direction: column;
  line-height: 1.25;

  & p {
    width: 90%;
    margin-block-end: 0
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 16px;

    & p {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    font-size: 15px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    white-space: normal;
    width: 95%;
    margin: auto;
  }
`;

const PreparationImage = styled.img`
  width: 45%;
  margin: auto;
  margin-right: 0;

  @media (max-width: 1150px) {
    width: 49%;
  }

  @media (max-width: 1023px) {
    width: 70%;
    margin: auto;
    margin-top: 5%;
  }

  @media (max-width: 700px) {
    width: 90%;
  }

  @media (max-width: 550px) {
    width: 95%;
  }
`;

const ContactSection = styled.div`
  max-width: 1920px;
  margin: auto;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 100px;

  @media (max-width: 1350px) {
    padding: 50px;
  }

  @media (max-width: 550px) {
    padding: 50px 0 ;
  }
`;

const ContactHeadline = styled.p`
  font-size: 32px;
  font-weight: 900;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 3%;

  @media (max-width: 1250px) {
    font-size: 26px;
  }

  @media (max-width: 1023px) {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
    white-space: normal;
  }

  @media (max-width: 550px) {
    font-size: 18px;
    width: 95%;
    margin: auto;
    padding-bottom: 3%;
    padding-top: 2.5%;
  }
`;

const ContactSubtext = styled.p`
  white-space: pre-wrap;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 3%;

  & p {
    margin-top: 0;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1600px) {
    font-size: 18px;
    white-space: normal;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 95%;
    margin: auto;
    margin-bottom: 3%;
    white-space: normal;
  }
`;

const ContactButton = styled(HeaderButton)`
  margin: auto;

  @media (max-width: 1250px) {
    font-weight: 900;
  }

  @media (max-width: 450px) {
    margin: 0 15px;
  }
`;

const InfoTextContactButton = styled(InfoTextHeaderButton)`

`;

const InfoContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 100px 100px 0 100px;

  @media (max-width: 1350px) {
    padding: 100px 50px 0 50px;
  }

  @media (max-width: 1023px) {
    padding: 50px 25px 0 25px;
  }

  @media (max-width: 550px) {
    padding: 50px 0 0 0;
  }
`;

const InfoSection1 = styled.div`
  color: var(--color-dark-grey);
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  & img {
    width: 45%;
    height: 100%;
    margin: auto;
    margin-right: 0;

    @media (max-width: 1150px) {
      width: 49%;
    }

    @media (max-width: 1023px) {
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }
  
    @media (max-width: 700px) {
      width: 90%;
    }
  
    @media (max-width: 550px) {
      width: 95%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (max-width: 550px) {
    padding-bottom: 50px;
  }
`;

const InfoTextContainer = styled.div`
  width: 45%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const InfoHeadline = styled(FAQHeadline)`
  margin-bottom: 3%;

  @media (max-width: 550px) {
    width: 95%;
    margin: auto;

  }
`;

const InfoText = styled.div`
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.25;

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  & ul {
    @media (max-width: 450px) {
      padding-left: 20px;
    }
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    white-space: normal;
    width: 95%;
    margin: auto;
  }

`;

const InfoSection2 = styled(InfoSection1)`
  flex-direction: row-reverse;

  & img {
    margin: auto;
    margin-left: 0;

    @media (max-width: 1023px) {
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }

    @media (max-width: 700px) {
      width: 90%;
    }
  
    @media (max-width: 550px) {
      width: 95%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const InfoSection3 = styled(InfoSection1)`

`;

export default class PurposeSubpages extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
  };

  // eslint-disable-next-line class-methods-use-this
  renderContent(page) {
    const id = page?.purposeSubpage?.id || Math.random();

    const submenu = page?.submenus?.edges?.find((menu) => menu?.node?.frontmatter?.submenuId === page?.purposeSubpage?.frontmatter?.submenuId)?.node;

    return (
      <SubpageContainer key={id}>
        <MainSection>
          <TealBackground>
            <SubpageHeader>
              <TextSectionHeader>
                <Headline>
                  <MDXRenderer>{page?.purposeSubpage?.frontmatter?.subpageHeadline}</MDXRenderer>
                </Headline>
                <Subtext>
                  <MDXRenderer>{page?.purposeSubpage?.frontmatter?.purposeSubpageSubtext}</MDXRenderer>
                </Subtext>
                <HeaderButton
                  type="button"
                  onClick={() => {
                    navigate(localizedPath({
                      locale: `${submenu?.fields?.locale}`,
                      path: '/contact',
                      defaultLang: 'de',
                      prefixDefault: false,
                    }));
                  }}
                >
                  { page?.purposeSubpage?.frontmatter?.buttonTextHeader || 'BUTTONTEXT NOT FOUND' }
                </HeaderButton>
                <InfoTextHeaderButton>{page?.purposeSubpage?.frontmatter?.infoTextButton}</InfoTextHeaderButton>
              </TextSectionHeader>
              <ImageWrapperHeader>
                <BackgroundBubble />
                <ScreenContainerHeader url={page?.purposeSubpage?.frontmatter?.subpageHeaderImage} />
                <ImageContainerHeader />
                <StatementContainer>
                  <StarSection>
                    <StarImage />
                    <StarImage2 />
                    <StarImage3 />
                  </StarSection>
                  <Statement>
                    <MDXRenderer>{page?.purposeSubpage?.frontmatter?.statementHeader}</MDXRenderer>
                  </Statement>
                </StatementContainer>
              </ImageWrapperHeader>
            </SubpageHeader>
            <CustomerReferencesContainerHeader>
              {page?.purposeSubpage?.frontmatter?.customerReferenceImagesHeader.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <CustomerImage key={index}>
                  <img src={item.customerReferenceImageHeader} alt={item.altAttributeCustomerImageHeader} loading="lazy" />
                </CustomerImage>
              ))}
            </CustomerReferencesContainerHeader>
          </TealBackground>
          {page?.purposeSubpage?.frontmatter?.subpageVideo && (
            <VideoContainer>
              <VideoHeadline>
                <MDXRenderer>{page?.purposeSubpage?.frontmatter?.videoHeadline}</MDXRenderer>
              </VideoHeadline>
              <VideoSection>
                <iframe
                  src={page?.purposeSubpage?.frontmatter?.subpageVideo}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </VideoSection>
            </VideoContainer>
          )}
          <ShareLinkSection>
            <ShareLink>
              <a
                href={page?.purposeSubpage?.frontmatter?.shareLink}
                target="_blank"
                rel="noreferrer"
              >
                {page?.purposeSubpage?.frontmatter?.shareLinkButtonText}
              </a>
            </ShareLink>
            <ShareLinkText>{page?.purposeSubpage?.frontmatter?.shareLinkSubtext}</ShareLinkText>
          </ShareLinkSection>
          <TrustFactContainer>
            {page?.purposeSubpage?.frontmatter?.trustFactsSubpage.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TrustFactSection key={index}>
                <TrustFactIcon url={item?.trustFactIcon} />
                <TrustFactHeadline>{item?.trustFactHeadline}</TrustFactHeadline>
                <TrustFactText>{item?.trustFactText}</TrustFactText>
              </TrustFactSection>
            ))}
          </TrustFactContainer>
          <CustomerReferenceFromHomepage>
            <CustomerReferences data={page?.purposeSubpage?.frontmatter} locale={page?.purposeSubpage?.fields?.locale} />
          </CustomerReferenceFromHomepage>
          <FAQContainer>
            <FAQHeadline>{page?.purposeSubpage?.frontmatter?.FAQsHeadline}</FAQHeadline>
            {page?.purposeSubpage?.frontmatter?.FAQs.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FAQSection key={index} className="tab">
                <input type="checkbox" name="accordion" id={`accordeon-${index}`} />
                <label htmlFor={`accordeon-${index}`} className="tab__label">
                  <h2>{item?.question}</h2>
                  <svg width="46" height="26" viewBox="0 0 46 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* eslint-disable-next-line max-len, max-len */}
                    <path d="M20.8787 25.1213C22.0503 26.2929 23.9497 26.2929 25.1213 25.1213L44.2132 6.02944C45.3848 4.85787 45.3848 2.95837 44.2132 1.7868C43.0416 0.615224 41.1421 0.615224 39.9706 1.7868L23 18.7574L6.02944 1.7868C4.85787 0.615224 2.95837 0.615224 1.7868 1.7868C0.615224 2.95837 0.615224 4.85787 1.7868 6.02944L20.8787 25.1213ZM20 22V23H26V22H20Z" fill="#3A3A3A" />
                  </svg>
                </label>
                <div className="tab__content">
                  <p>{item?.answer}</p>
                </div>
              </FAQSection>
            ))}
          </FAQContainer>
          <PreparationContainer>
            <PreparationTextBlock>
              <PreparationHeadline>{page?.purposeSubpage?.frontmatter?.preparationHeadline}</PreparationHeadline>
              <MDXRenderer>{page?.purposeSubpage?.frontmatter?.preparationText}</MDXRenderer>
            </PreparationTextBlock>
            <PreparationImage
              src={page?.purposeSubpage?.frontmatter?.preparationImage}
              alt={page?.purposeSubpage?.frontmatter?.altAttributePreparationImage}
            />
          </PreparationContainer>
          <TealBackground>
            <ContactSection>
              <ContactHeadline>{page?.purposeSubpage?.frontmatter?.contactHeadline}</ContactHeadline>
              <ContactSubtext>{page?.purposeSubpage?.frontmatter?.contactSubtext}</ContactSubtext>
              <ContactButton
                type="button"
                onClick={() => {
                  navigate(localizedPath({
                    locale: `${submenu?.fields?.locale}`,
                    path: '/contact',
                    defaultLang: 'de',
                    prefixDefault: false,
                  }));
                }}
              >
                { page?.purposeSubpage?.frontmatter?.contactbuttonText || 'BUTTONTEXT NOT FOUND' }
              </ContactButton>
              <InfoTextContactButton>{page?.purposeSubpage?.frontmatter?.contactInfoTextButton}</InfoTextContactButton>
            </ContactSection>
          </TealBackground>
          <InfoContainer>
            <InfoSection1>
              <InfoTextContainer>
                <InfoHeadline>{page?.purposeSubpage?.frontmatter?.infoSectionHeadline1}</InfoHeadline>
                <InfoText>
                  <MDXRenderer>{page?.purposeSubpage?.frontmatter?.infoSectionText1}</MDXRenderer>
                </InfoText>
              </InfoTextContainer>
              <img src={page?.purposeSubpage?.frontmatter?.infoSectionImage1} alt={page?.purposeSubpage?.frontmatter?.altAttributeInfoSectionImage1} />
            </InfoSection1>
            <InfoSection2>
              <InfoTextContainer>
                <InfoHeadline>{page?.purposeSubpage?.frontmatter?.infoSectionHeadline2}</InfoHeadline>
                <InfoText>
                  <MDXRenderer>{page?.purposeSubpage?.frontmatter?.infoSectionText2}</MDXRenderer>
                </InfoText>
              </InfoTextContainer>
              <img src={page?.purposeSubpage?.frontmatter?.infoSectionImage2} alt={page?.purposeSubpage?.frontmatter?.altAttributeInfoSectionImage2} />
            </InfoSection2>
            { page?.purposeSubpage?.frontmatter?.infoSectionHeadline3 && (
              <InfoSection3>
                <InfoTextContainer>
                  <InfoHeadline>{page?.purposeSubpage?.frontmatter?.infoSectionHeadline3}</InfoHeadline>
                  <InfoText>
                    <MDXRenderer>{page?.purposeSubpage?.frontmatter?.infoSectionText3}</MDXRenderer>
                  </InfoText>
                </InfoTextContainer>
                <img src={page?.purposeSubpage?.frontmatter?.infoSectionImage3} alt={page?.purposeSubpage?.frontmatter?.altAttributeInfoSectionImage3} />
              </InfoSection3>
            )}
          </InfoContainer>
        </MainSection>
      </SubpageContainer>
    );
  }

  render() {
    const { pageContext, data } = this.props;
    const page = data || {};

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath} submenuId={page?.purposeSubpage?.frontmatter?.submenuId}>
        <Helmet>
          <title>{`${data?.purposeSubpage?.frontmatter?.titleMetaTag} | presono`}</title>
          <meta
            name="description"
            content={data?.purposeSubpage?.frontmatter?.snippetTextSubpage}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.purposeSubpage?.frontmatter?.titleMetaTag} />
          <meta property="og:description" content={data?.purposeSubpage?.frontmatter?.snippetTextSubpage} />
          <meta property="og:image" content={`https://presono.com${data?.purposeSubpage?.frontmatter?.subpageHeaderImage}`} />
          <meta name="twitter:title" content={data?.purposeSubpage?.frontmatter?.titleMetaTag} />
          <meta name="twitter:description" content={data?.purposeSubpage?.frontmatter?.snippetTextSubpage} />
          <meta name="twitter:image" content={`https://presono.com${data?.purposeSubpage?.frontmatter?.subpageHeaderImage}`} />
        </Helmet>
        <SubpageWrapper className="off-white-background">
          { this.renderContent(page) }
        </SubpageWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
query SubpageQuery($title: String!, $locale: String!) {
  purposeSubpage: mdx(
    fields: {locale: {eq: $locale}}
    frontmatter: {title: {eq: $title}, layout: {eq: "purposeSubpage"}}
  ) {
    frontmatter {
      title
      titleMetaTag
      snippetTextSubpage
      submenuId
      subpageId
      submenuSubheadline
      subpageHeadline
      purposeSubpageSubtext
      buttonTextHeader
      infoTextButton
      subpageHeaderImage
      statementHeader
      customerReferenceImagesHeader {
        customerReferenceImageHeader
        altAttributeCustomerImageHeader
      }
      videoHeadline
      subpageVideo
      shareLinkButtonText
      shareLink
      shareLinkSubtext
      trustFactsSubpage {
        trustFactIcon
        trustFactHeadline
        trustFactText
      }
      customerReferenceHeadline
      extraStylingHeadline
      customerReferenceImages {
        customerReferenceImage
        altAttributeCustomerImage
      }
      customerQuotations {
        customerLogo
        customerQuotation
        customerName
        readMoreButton
        buttonLink
      }
      FAQsHeadline
      FAQs {
        question
        answer
      }
      preparationHeadline
      preparationText
      preparationImage
      altAttributePreparationImage
      contactHeadline
      contactSubtext
      contactbuttonText
      contactInfoTextButton
      infoSectionHeadline1
      infoSectionText1
      infoSectionImage1
      altAttributeInfoSectionImage1
      infoSectionHeadline2
      infoSectionText2
      infoSectionImage2
      altAttributeInfoSectionImage2
      infoSectionHeadline3
      infoSectionText3
      infoSectionImage3
      altAttributeInfoSectionImage3
    }
    id
    slug
    fields {
      locale
    }
  }
  submenus: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId] }
    filter: {
      frontmatter: { layout: {eq: "submenu"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          submenuId
          title
        }
        fields {
          locale
        }
      }
    }
  }
  purposeSubpages: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId, frontmatter___subpageId] }
    filter: {
      frontmatter: { layout: {eq: "purposeSubpage"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          title
          submenuId
          subpageId
          submenuSubheadline
        }
        slug
        id
        fields {
          locale
        }
      }
    }
  }
}
`;
